import { createRef } from "react"
import { Vector3 } from "three"

const state = {
  sections: 14,
  pages: 13,
  zoom: 75,
  paragraphs: [
    // TODO: crop everything to have aspect ratio of 2!
    {
      offset: 1,
      factor: 1.75,
      header: "About Me",
      image: "/lee_adam_skate_portrait.jpg",
      aspect: 1.422,
      text: "Hi, my name is Adam Lee! I like deep learning, startups, and web development!"
    },
    {
      offset: 3,
      factor: 1.25,
      header: "Nautilus",
      image: "/nautilus.jpg",
      aspect: 1.845,
      text:
        "Leveraging DevOps in VR experiences to advance the future of system administration through the cloud. Kubernetes, Docker, Houdini, Unreal Engine, Bash, Python."
    },
    {
      offset: 4,
      factor: 1.25,
      header: "DetecDrone",
      image: "/drone.jpg",
      aspect: 2,
      text:
        "Researching autonomous navigation in drones and creating simulation environments for cheap experimentation. Tensorflow, Unreal Engine, Unity."
    },
    {
      offset: 5,
      factor: 2.0,
      header: "Yaksh Lab",
      image: "/yaksh-sample.png",
      aspect: 1.93,
      text:
        "Applying deep learning to computer vision in order to extract insights in the field of anesthesiology. Tensorflow, Docker, Bash, Python."
    },
    {
      offset: 7,
      factor: 1.75,
      header: "Education.com",
      image: "/roly.png",
      aspect: 1.556,
      text:
        "Developed a deep learning powered recommendation system for educational content. Created new web experiences across the stack. Tensorflow, MySQL, MongoDB, React, PHP, ElasticSearch."
    },
    { offset: 8,
      factor: 1.00,
      header: "Endorsify",
      image: "/smaller_endorsify.png",
      aspect: 1.733,
      text: "Led a team of engineers to build a site-wide communication platform. Refined machine learning algorithms. Laravel, React, MySQL, Scikit-Learn, Pandas, Matplotlib."
    },
    { offset: 10,
      factor: 1.75,
      header: "WriteGen",
      image: "/writegen_sample.png",
      aspect: 2.05,
      text: "Created an AI writer to curate writing from various inspirations - sample Spongebob script shown above. Tensorflow, Flask, Kubernetes, Docker, React."
    },
    { offset: 11,
      factor: 1.05,
      header: "RL Algorithms",
      image: "/upside-down-ant.png",
      aspect: 2.22,
      text: "Writing deep reinforcement learning algorithms from scratch. Pytorch."
    },
    { offset: 12,
      factor: 1.05,
      header: "Document Denoiser",
      image: "/comparison-dirty-doc.png",
      aspect: 1.92,
      text: "Utilizing deep learning and generative methods to remove noise from images. Pytorch."
    }
  ],
  stripes: [
    { offset: 0, color: "#FFB8D1", height: 13 },
    { offset: 3.1, color: "#FFB8D1", height: 11 },
    { offset: 4.5, color: "#FFB8D1", height: 8 },
    { offset: 6.3, color: "#FFB8D1", height: 18 },
    { offset: 8, color: "#FFB8D1", height: 11 },
    { offset: 11.3, color: "#FFB8D1", height: 13 }
  ],
  diamonds: [
    { x: 0, offset: 0.15, pos: new Vector3(), scale: 0.6, factor: 1.8 },
    // { x: 2, offset: 1.1, pos: new Vector3(), scale: 0.8, factor: 2.1 },
    { x: -3.7, offset: 1.8, pos: new Vector3(), scale: 0.8, factor: 2.5 },
    // { x: 0, offset: 3.2, pos: new Vector3(), scale: 0.8, factor: 1.75 },
    // { x: 0, offset: 4, pos: new Vector3(), scale: 0.8, factor: 2.5 },
    // { x: 2, offset: 5.5, pos: new Vector3(), scale: 1.25, factor: 0.85 },
    { x: -2, offset: 5.9, pos: new Vector3(), scale: 0.8, factor: 2 },
    // { x: -5, offset: 7, pos: new Vector3(), scale: 0.8, factor: 2 },
    { x: -3, offset: 9.1, pos: new Vector3(), scale: 0.9, factor: 2 },
    // { x: 0, offset: 8, pos: new Vector3(), scale: 1.5, factor: 6 },
    { x: 0, offset: 13, pos: new Vector3(), scale: 1.5, factor: 6 }
  ],
  top: createRef()
}

export default state
